import React, { useEffect, useState } from 'react'
import { Table, Spin, message } from 'antd'
import Image from 'next/image';
import { InputCustom, SearchCustom } from '../MainCode/MainForm'
import StorageApi from '../APIConfig/MainApi';
import { Excel } from "antd-table-saveas-excel";
import { css } from '@emotion/css';

function DataTableGroup(props) {
  const [paging, setPagnition] = useState(10);
  const { Column, ColumnGroup } = Table;
  const [columns, setColumns] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [total_rows, setColumnsNow] = useState(0);
  const [loader_download, setLoaderDownload] = useState(false);
  const [download_file, setDownloadFile] = useState(false);
  const [list_download, setListDownload] = useState([]);
  const [data_download, setDataDownload] = useState([]);

  useEffect(() => {
    // untuk columns
    var column = props.columns;
    if (props.nomor != 'hidden') {
      column.unshift({
        title: 'No',
        dataIndex: 'no',
        width: '3%',
        align: 'center',
        fixed: 'left',
        sorter: (a, b) => a.no - b.no,
      })
    }
    setColumns(column)
    // props.download ? DownloadExcel(props.download) : ("")
  }, [props.columns])

  const datas = props.dataSource;
  const data = datas.filter(
    item => item.nrp && item.nrp.toLowerCase().includes(filterText.toLowerCase())
      || item.kode_data && item.kode_data.includes(filterText)
      || item.data && item.data.toLowerCase().includes(filterText.toLowerCase())
      || item.tempat_pelaksana && item.tempat_pelaksana.toLowerCase().includes(filterText.toLowerCase())
      || item.kelompok_sasaran && item.kelompok_sasaran.toLowerCase().includes(filterText.toLowerCase())
      || item.program_kerja && item.program_kerja.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_rsp && item.jenis_rsp.toLowerCase().includes(filterText.toLowerCase())
      || item.aktifitas && item.aktifitas.toLowerCase().includes(filterText.toLowerCase())
      || item.status_pesan && item.status_pesan.toLowerCase().includes(filterText.toLowerCase())
      || item.media && item.media.toLowerCase().includes(filterText.toLowerCase())
      || item.hari && item.hari.toLowerCase().includes(filterText.toLowerCase())
      || item.broadcast && item.broadcast.toLowerCase().includes(filterText.toLowerCase())
      || item.pelaksana_pekerjaan && item.pelaksana_pekerjaan.toLowerCase().includes(filterText.toLowerCase())
      || item.login_dari && item.login_dari.toLowerCase().includes(filterText.toLowerCase())
      || item.kaposko && item.kaposko.toLowerCase().includes(filterText.toLowerCase())
      || item.penanggung_jawab && item.penanggung_jawab.toLowerCase().includes(filterText.toLowerCase())
      || item.username && item.username.toLowerCase().includes(filterText.toLowerCase())
      || item.total_populasi && item.total_populasi.toLowerCase().includes(filterText.toLowerCase())
      || item.nama_cctv && item.nama_cctv.toLowerCase().includes(filterText.toLowerCase())
      || item.subcategories && item.subcategories.toLowerCase().includes(filterText.toLowerCase())
      || item.instansi && item.instansi.toLowerCase().includes(filterText.toLowerCase())
      || item.modul && item.modul.toLowerCase().includes(filterText.toLowerCase())
      || item.nama_pemohon && item.nama_pemohon.toLowerCase().includes(filterText.toLowerCase())
      || item.categories && item.categories.toLowerCase().includes(filterText.toLowerCase())
      || item.potensi_gangguan && item.potensi_gangguan.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_bencana && item.jenis_bencana.toLowerCase().includes(filterText.toLowerCase())
      || item.kategori_giat && item.kategori_giat.toLowerCase().includes(filterText.toLowerCase())
      || item.info_data_awal && item.info_data_awal.toLowerCase().includes(filterText.toLowerCase())
      || item.judul_news && item.judul_news.toLowerCase().includes(filterText.toLowerCase())
      || item.nama_fasilitas && item.nama_fasilitas.toLowerCase().includes(filterText.toLowerCase())
      || item.activity && item.activity.toLowerCase().includes(filterText.toLowerCase())
      || item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
      || item.layanan_darurat && item.layanan_darurat.toLowerCase().includes(filterText.toLowerCase())
      || item.manage_route && item.manage_route.toLowerCase().includes(filterText.toLowerCase())
      || item.nama_event && item.nama_event.toLowerCase().includes(filterText.toLowerCase())
      || item.sub_fasilitas && item.sub_fasilitas.toLowerCase().includes(filterText.toLowerCase())
      || item.judul && item.judul.toLowerCase().includes(filterText.toLowerCase())
      || item.nama_kuat_personil && item.nama_kuat_personil.toLowerCase().includes(filterText.toLowerCase())
      || item.provinsi && item.provinsi.toLowerCase().includes(filterText.toLowerCase())
      || item.sotk && item.sotk.toLowerCase().includes(filterText.toLowerCase())
      || item.nama_petugas && item.nama_petugas.toLowerCase().includes(filterText.toLowerCase())
      || item.nama_jalan_s && item.nama_jalan_s.toLowerCase().includes(filterText.toLowerCase())
      || item.teguran && item.teguran.toLowerCase().includes(filterText.toLowerCase())
      || item.situasi_lalin && item.situasi_lalin.toLowerCase().includes(filterText.toLowerCase())
      || item.kode_unit && item.kode_unit.toLowerCase().includes(filterText.toLowerCase())
      || item.kode_sub_unit && item.kode_sub_unit.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_pengemudi && item.jenis_pengemudi.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_kendaraan && item.jenis_kendaraan.toLowerCase().includes(filterText.toLowerCase())
      || item.nama_jalan && item.nama_jalan.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_kegiatan && item.jenis_kegiatan.toLowerCase().includes(filterText.toLowerCase())
      || item.dasar_giat && item.dasar_giat.toLowerCase().includes(filterText.toLowerCase())
      || item.kerusakan_object && item.kerusakan_object.toLowerCase().includes(filterText.toLowerCase())
      || item.nama_pelapor && item.nama_pelapor.toLowerCase().includes(filterText.toLowerCase())
      || item.kebutuhan_peralatan && item.kebutuhan_peralatan.toLowerCase().includes(filterText.toLowerCase())
      || item.fungsi_kepolisian && item.fungsi_kepolisian.toLowerCase().includes(filterText.toLowerCase())
      || item.saluran && item.saluran.toLowerCase().includes(filterText.toLowerCase())
      || item.kategori_pelapor && item.kategori_pelapor.toLowerCase().includes(filterText.toLowerCase())
      || item.status_jalan && item.status_jalan.toLowerCase().includes(filterText.toLowerCase())
      || item.sub_fungsi_kepolisian && item.sub_fungsi_kepolisian.toLowerCase().includes(filterText.toLowerCase())
      || item.fungsi_jalan && item.fungsi_jalan.toLowerCase().includes(filterText.toLowerCase())
      || item.situasi_lalin && item.situasi_lalin.toLowerCase().includes(filterText.toLowerCase())
      || item.kategori && item.kategori.toLowerCase().includes(filterText.toLowerCase())
      || item.sub_kategori && item.sub_kategori.toLowerCase().includes(filterText.toLowerCase())
      || item.golongan && item.golongan.toLowerCase().includes(filterText.toLowerCase())
      || item.jabatan && item.jabatan.toLowerCase().includes(filterText.toLowerCase())
      || item.nama && item.nama.toLowerCase().includes(filterText.toLowerCase())
      || item.pang_nam && item.pang_nam.toLowerCase().includes(filterText.toLowerCase())
      || item.sub_unit && item.sub_unit.toLowerCase().includes(filterText.toLowerCase())
      || item.page && item.page.toLowerCase().includes(filterText.toLowerCase())
      || item.pendidikan && item.pendidikan.toLowerCase().includes(filterText.toLowerCase())
      || item.penyelenggara && item.penyelenggara.toLowerCase().includes(filterText.toLowerCase())
      || item.polda && item.polda.toLowerCase().includes(filterText.toLowerCase())
      || item.polres && item.polres.toLowerCase().includes(filterText.toLowerCase())
      || item.status && item.status.toLowerCase().includes(filterText.toLowerCase())
      || item.kondisi_cuaca && item.kondisi_cuaca.toLowerCase().includes(filterText.toLowerCase())
      || item.kejadian_termonitor && item.kejadian_termonitor.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_layanan && item.jenis_layanan.toLowerCase().includes(filterText.toLowerCase())
      || item.kejadian_opsdal && item.kejadian_opsdal.toLowerCase().includes(filterText.toLowerCase())
      || item.cara_bertindak && item.cara_bertindak.toLowerCase().includes(filterText.toLowerCase())
      || item.panjang_antrian && item.panjang_antrian.toLowerCase().includes(filterText.toLowerCase())
      || item.penyebab_kemacetan && item.penyebab_kemacetan.toLowerCase().includes(filterText.toLowerCase())
      || item.object_kawal && item.object_kawal.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_project && item.jenis_project.toLowerCase().includes(filterText.toLowerCase())
      || item.kategori_device && item.kategori_device.toLowerCase().includes(filterText.toLowerCase())
      || item.kategori_aplikasi && item.kategori_aplikasi.toLowerCase().includes(filterText.toLowerCase())
      || item.status_device && item.status_device.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_wisata && item.jenis_wisata.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_event && item.jenis_event.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_service && item.jenis_service.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_berita && item.jenis_berita.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_galery && item.jenis_galery.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_pelatihan && item.jenis_pelatihan.toLowerCase().includes(filterText.toLowerCase())
      || item.jenis_pendidikan && item.jenis_pendidikan.toLowerCase().includes(filterText.toLowerCase())
      || item.status_pegawai && item.status_pegawai.toLowerCase().includes(filterText.toLowerCase())
      || item.link && item.link.toLowerCase().includes(filterText.toLowerCase())
      || item.kode_user_master && item.kode_user_master.toLowerCase().includes(filterText.toLowerCase())
      || item.unit && item.unit.toLowerCase().includes(filterText.toLowerCase())
      || item.smart_ops && item.smart_ops.toLowerCase().includes(filterText.toLowerCase())
      || item.kategori_fasilitas && item.kategori_fasilitas.toLowerCase().includes(filterText.toLowerCase())
  )

  const DataDownload = (link) => {
    setLoaderDownload(true)
    Promise.resolve(StorageApi.getData(link))
      .then(value => {
        const label = Object.keys(value.data.data[0]);
        const data = value.data.data;
        var title_download = [
          { title: "No", dataIndex: "no" }
        ]
        var data_download = []
        var data_real = []
        let judul = {}
        let data_mentah = []
        let data_array_1 = {}
        let number = []
        for (let index = 0; index < label.length; index++) {
          if (label[index].slice(-3) != "_id" && label[index] != "rowid") {
            title_download.push(
              { title: label[index], dataIndex: label[index] },
            )
          }
        }
        for (let index = 0; index < data.length; index++) {
          let coba = Object.keys(data[index]);
          judul = Object.keys(data[index])
          data_mentah.push(
            Object.values(data[index])
          )
        }

        for (let i = 0; i < data_mentah.length; i++) {
          number.push({ no: i + 1 })
          for (let a = 0; a < data_mentah[i].length; a++) {
            var m = judul[a]
            if (m.slice(-3) != "_id" && m != "rowid") {
              data_array_1 = { ...data_array_1, [m]: data_mentah[i][a] }
            }
          }
          data_real.push(data_array_1)
          data_download.push({ ...data_real[i], "no": number[i].no });
        }
        setListDownload(title_download)
        setDataDownload(data_download)
        setLoaderDownload(false)
      }).catch(error => {
        setListDownload([])
        setDataDownload([])
        setLoaderDownload(false)
      })
  }

  const DownloadExcel = async (link) => {
    DataDownload(link)
    const excel = new Excel();
    excel
      .addSheet(`${props.filename}`)
      .addColumns(list_download)
      .addDataSource(data_download, {
        str2Percent: true
      })
    if (excel.currentRow != total_rows) {
      excel.saveAs(`${props.filename}.xlsx`)
      setDownloadFile(false)
      setColumnsNow(excel.currentRow)
    } else {
      message.success("Data berhasil diperbaharui")
      setColumnsNow(excel.currentRow)
      setDownloadFile(true)
    }
  };

  const tableCSS = css({
    backgroundColor: 'white',
    '& table': {
      borderCollapse: 'collapse',
      borderWidth: '2px',
      borderColor: 'white'
    },
    '& thead > tr > th': {
      backgroundColor: '#19376D',
      color: 'white',
      borderWidth: '2px',
      borderColor: 'white',
    },
    '& thead > tr': {
      borderWidth: '2px',
      borderColor: 'white',
    },
    '& tbody > tr > td': {
      backgroundColor : '#F6F1F1',
      color: 'black',
      borderWidth: '6px',
      borderColor: 'white',
    }

  });

  return (
    <>
      {props.download ? (<>
        <div className='float-right mb-6 mt-10 xl:mt-0 md:mt-0'>
          <div className="flex items-center justify-end">
            {props.import ? (<>
              <button className=" -mt-14 xl:mt-5 md:mt-5 mr-4 py-3 px-4 rounded-md bg-[#7366FF] hover:bg-[#3f3892] font-semibold flex text-white" onClick={props.import}>
                <Image src='/icons/upload.svg' width={23} height={23} alt={'import'} />
                &nbsp; Import
              </button>
            </>) : (<></>)}
            <button className=" -mt-14 xl:mt-5 md:mt-5 py-3 px-4 rounded-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white" onClick={e => DownloadExcel(props.download)}>
              {loader_download ? (<>
                <Spin />
                &nbsp; {download_file ? 'Proses Download' : 'Pembaruan data'}
              </>) : (<>
                <Image src="/icons/download.svg" alt="unduh" width={20} height={20} className="mr-2" />
                &nbsp;  {download_file ? 'Download' : 'Perbarui data'}
              </>)}
            </button>
          </div>
        </div>
      </>) : (<></>)}
      {props.downloads ? (
        <button className=" -mt-14 xl:mt-5 md:mt-5 py-3 px-4 rounded-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white" onClick={props.downloads}>
          {props.prosesDownload ? (<>
            <Spin />
          </>) : (<>
            <Image src="/icons/download.svg" alt="unduh" width={20} height={20} className="mr-2" />
          </>)}
          &nbsp; Download
        </button>
      ) : ("")}
      <SearchCustom title={props.title} type="text" onKeyUp={e => setFilterText(e.target.value)} placeholder="Search" nrp="search" colorText={props.colorText} />
      <div className="overflow-x-auto flex flex-col mt-10">
        <div className="flex justify-start items-center">
          <InputCustom type="number" width="w-[60px] mb-2" onChange={e => setPagnition(e.target.value)} /> <span className={`${props.colorText}  text-white font-light -ml-3`}><b>{paging} rows/Page</b></span>
        </div>
        <Table 
        dataSource={data}
        loading={props.loading}
        size={'medium'}
        className={tableCSS}
        scroll={{ x: props.width  }}
        pagination={
          {
            pageSize: paging ? paging : 10,
          }
        }
        tableLayout={{ width: "30%" }}
        bordered
        >
          {columns.map(item=>{
            return(
              <>
                {item.groupTitle ? <>
                  <ColumnGroup title={item.title} className='!text-center'>
                  { item.dataSub?.map(item=>{
                      return(<>
                      <Column title={item.title} dataIndex={item.dataIndex} className='!text-center' />
                      </>)
                    })
                  }
                  </ColumnGroup>
                </> : <>
                <Column title={item.title} dataIndex={item.dataIndex} className='!text-center'/>
                </>}
              </>
            )
          })}
        </Table>
      </div>
    </>
  )
}
export default DataTableGroup
