import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Image, message } from 'antd'
import { getSession, signOut, useSession } from "next-auth/react"
import Link from 'next/link'
import { useIdleTimer } from 'react-idle-timer'
import StorageApi from '../APIConfig/MainApi'

const ProfileMenu = (props) => {
  const {colorText,bgColorCard} = props
  const [openMenu, setOpenMenu] = useState(false)
  const router = useRouter();
  const { data: session, status } = useSession()
  useEffect(() => {
    getSess();
    checkSession();
  }, []);


  const logout = async (e) => {
    const sessi = await getSession()
    Promise.resolve(StorageApi.hapusDataLogin("sso_check", sessi.id_unique))
      .then(value => {
        message.success("Berhasil logout")
        hapus_sessi()
      }).catch(error => {
        // console.log('error');
        hapus_sessi()
      })
  }

  const hapus_sessi = async () => {
    await signOut({
      redirect: false
    });
    getSess();
  }

  async function getSess() {
    const session = await getSession()
    if (!session) {
      router.push('/');
    }
  }

  // const onIdle = () => {
  //   logout()
  //   message.info("Session Anda Telah Berakhir")
  // }

  const checkSession = async () => {
    const sesi = await getSession()
    Promise.resolve(StorageApi.detailData('sso_check/checksession', sesi.id_unique))
      .then(value => {
        // if (!value.status) {
        //   hapus_sessi()
        //   message.success("Session anda telah berakhir")
        // }
      }).catch(error => {
        message.success("Session anda telah berakhir")
        hapus_sessi()
      })
  }

  // useIdleTimer({ onIdle, timeout: 420000 })



  const menunonaktif = "inline-flex items-center w-full px-4 py-2 mt-1 text-base text-gray-900 transition duration-500 focus:shadow-outline ease-in-out transform hover:border-r-8 hover:border-[#003AD2] hover:bg-[#E5E5E5]";
  return (
    <div>
      <div className='flex grid-cols-2 gap-2 text-left px-10'>
        <div className='' onClick={e => setOpenMenu(!openMenu)}>
          <Image src={"/images/profile.png"} width={60} preview={false}/>
        </div>
        <div className={`font-bold mt-2 ${colorText}`}>
          <div className='text-start' onClick={e => setOpenMenu(!openMenu)}>
            <button className='xl:block md:block hidden font-medium capitalize text-[11px] xl:text-[14px] md:text-[14px]'>{session?.nama}
              <div>
                <div className='flex'>
                  <span className='flex-none uppercase text-[8px] xl:text-[9px] md:text-[10px]'>{session?.unit_fungsional} </span>
                </div>
              </div>
            </button>
            <div className={`bg-[#FFFFFF]  z-50 border rounded-md absolute xl:w-[10%] md:w-[15%] xl:-ml-10 md:-ml-10 -ml-24 xl:mt-3 md:mt-3 mt-14 ${openMenu ? 'block' : 'hidden'}`}>
              <nav className="flex-1">
                <div className='text-center font-bold p-2 xl:hidden md:hidden block'>
                  <p className='flex-none uppercase text-[8px] xl:text-[9px] md:text-[10px] -mb-0.5'>{session?.nama} </p>
                  <p className='flex-none uppercase text-[6px]'>{session?.unit_fungsional} </p>
                </div>
                <ul className="">
                  <li>
                    <a className={menunonaktif}>
                      <div className="container flex">
                        <Link href={'/Hrm/profile'}>
                          <button className="flex">
                            <Image src={'/icons/profile.svg'} alt="image" width={20} height={20} preview={false}/>
                            <span className="ml-2 text-[14px] ">Profile</span>
                          </button>
                        </Link>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className={menunonaktif}>
                      <div className="container flex">
                        <button className="flex" onClick={e => logout()}>
                          <Image src={'/icons/logout.svg'} alt="image" width={20} height={20} preview={false}/>
                          <span className="ml-2 text-[14px]">Sign Out</span>
                        </button>
                      </div>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}


export default ProfileMenu
